.manager-heading {
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  margin-top: 2.5rem;
}

.manager-subheading {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 20px;
  font-style: italic;
  margin-top: -10px;
}

.manager-container {
  display: flex;
  justify-content: center;
}

.manager-cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.manager-card-title {
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 0.8rem;
  font-size: 24px;
  font-style: bold;
}

.manager-card {
  width: 18rem;
  height: 28rem;
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;
  border: 1px solid rgba(0,0,0,.125);
}

.manager-card-image {
  height: 75%;
  width: 100%;
  object-fit: cover;
}

.manager-button-container {
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.manager-button {
  width: auto;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  padding: 0px 10px;
  font-size: 16px;
  width: 88%;
  height: 50px;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
}

.manager-button:hover {
  background-color: #025ec0;
  border-color: #004085;
}

.admin-link-container {
  position: absolute;
  top: 5rem;
  left: 30px;
  padding: 10px;
  border-radius: 5px;
}

.admin-link {
  text-decoration: underline;
  color: #286cf6;
  font-size: 16px;
  font-weight: 600;
}

/* Tablet Portrait Mode */
@media (max-width: 814px) and (max-height: 1200px) {
  .manager-heading {
    font-size: 18px;
    margin-top: 3.5rem;
  }
  
  .manager-subheading {
    font-size: 15px;
    margin-bottom: 24px;
  }
  
  .manager-container {
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0 3rem;
  }
  
  .manager-cards-container {
    flex-wrap: wrap;
    margin: 0;
    justify-content: center;
    max-width: 800px;
  }
  
  .manager-card-title {
    font-size: 18px;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  
  .manager-card {
    width: 12rem;
    height: 20rem;
  }
  
  .manager-card-image {
    height: 72%;
  }
  
  .manager-button {
    width: 85%;
    font-size: 14px;
  }
}

/* Tablet Landscape Mode */
@media (max-width: 1200px) and (max-height: 714px) and (orientation: landscape) {
  .manager-heading {
    font-size: 18px;
    margin-top: 25px;
  }
  
  .manager-subheading {
    font-size: 16px;
    margin-bottom: 24px;
  }
  
  .manager-container {
    overflow-x: auto;
    padding-bottom: 20px;
    gap: 1.5rem;
  }
  
  .manager-cards-container {
    flex-wrap: nowrap;
    margin: 0;
  }
  
  .manager-card-title {
    font-size: 18px;
    margin-bottom: 12px;
    margin-top: 5px;
  }
  
  .manager-card {
    width: 14rem;
    height: 22rem;
  }
  
  .manager-card-image {
    height: 74%;
  }
  
  .manager-button {
    width: 85%;
    font-size: 16px;
  }
}
