/* Root Variables */
:root {
  --glass-bg: rgba(255, 255, 255, 0.45);
  --glass-border: rgba(255, 255, 255, 0.2);
  --glass-shadow: rgba(104, 139, 255, 0.07);
  --primary-color: #007AFF;
  --error-color: #dc3545;
  --text-color: #2d3250;
  --secondary-text: rgba(45, 50, 80, 0.8);
  --light-bg: #f8faff;
}

/* Simulation Container */
.simulation-container {
  padding: 0px 1.5rem;
  max-width: 1400px;
  margin: 0 auto;
}

/* Simulation Header */
.simulation-header {
  text-align: center;
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.05), rgba(151, 164, 225, 0.1));
  padding: 1rem 1rem;
  border-radius: 20px;
}

.simulation-header h2 {
  font-size: 2.2rem;
  font-weight: 700;
  color: var(--text-color);
  background: linear-gradient(90deg, var(--primary-color), #a5b4ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 1rem;
  padding: 5px;
  text-align: center;
}

.simulation-header .lead {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  font-style: italic;
}

.action-buttons-container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  gap: 12px;
  margin-top: 1.5rem;
}

.action-button {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.8), rgba(151, 164, 225, 0.8));
  color: white;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
}

.action-button:hover {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.9), rgba(151, 164, 225, 0.9));
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 122, 255, 0.25);
  color: white;
}

.action-button i {
  margin-right: 8px;
}

/* Color-coded efficiency indicators */
.efficiency-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
}

.efficiency-high {
  background: linear-gradient(90deg, #86dbbc, #28a745);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 2.5rem;
}

.efficiency-medium {
  background: linear-gradient(90deg, #e1c591, #ffc107);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 2.5rem;
}

.efficiency-low {
  background: linear-gradient(90deg, #ec4646, #e7abb0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 2.5rem;
}

.efficiency-legend {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 1rem 0;
  padding: 12px 15px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  justify-content: center;
  box-shadow: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
}

.efficiency-legend:hover {
  box-shadow: 0 8px 25px rgba(31, 38, 135, 0.15);
  border: 1px solid transparent;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.85rem;
}

/* Floating Help Button */
.floating-help-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(135deg, #007AFF, #a5b4ff);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 20px rgba(0, 122, 255, 0.4);
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease;
  border: none;
}

.floating-help-button:hover {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 6px 25px rgba(0, 122, 255, 0.5);
}

.floating-help-button i {
  font-size: 24px;
}

.mass-balance-container h3{
  text-align: center;
  font-size: 1.5rem;
}

/* Help Contact Modal */
.help-contact-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-contact-modal {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95));
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 24px rgba(104, 139, 255, 0.12),
              0 1px 2px rgba(255, 255, 255, 0.3);
  animation: slideUp 0.3s ease-out;
}

.help-contact-modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid rgba(130, 146, 250, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.help-contact-modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0f172a;
  margin: 0;
  background: linear-gradient(90deg, #007AFF, #a5b4ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.help-contact-modal-body {
  padding: 24px;
  max-height: 70vh;
  overflow-y: auto;
}

.help-contact-form-group {
  margin-bottom: 20px;
}

.help-contact-label {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #475569;
  margin-bottom: 8px;
}

.help-contact-input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid rgba(130, 146, 250, 0.3);
  border-radius: 10px;
  font-size: 0.95rem;
  transition: all 0.2s;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.05);
}

.help-contact-input:focus {
  outline: none;
  border-color: #007AFF;
  box-shadow: 0 0 0 3px rgba(130, 146, 250, 0.15);
}

.help-contact-textarea {
  min-height: 120px;
  resize: vertical;
}

.help-contact-button {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.8), rgba(151, 164, 225, 0.8));
  color: white;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  border: none;
  border-radius: 10px;
  padding: 12px 24px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.15);
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
}

.help-contact-button:hover {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.9), rgba(151, 164, 225, 0.9));
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 122, 255, 0.25);
}

.help-contact-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.help-contact-close {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #475569;
  transition: color 0.2s;
}

.help-contact-close:hover {
  color: #007AFF;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .simulation-header h2 {
    font-size: 1.8rem;
  }
  
  .simulation-header .lead {
    font-size: 1.1rem;
  }
  
  .action-buttons-container {
    flex-direction: column;
    align-items: center;
  }
  
  .action-button {
    width: 100%;
    max-width: 250px;
  }
  
  .floating-help-button {
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
  }
  
  .help-contact-modal {
    width: 95%;
    max-height: 90vh;
  }
  
  .help-contact-modal-body {
    padding: 16px;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Glass Effect - Enhanced default shadow */
.glass-effect {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 20px;
  border: 1px solid rgba(104, 139, 255, 0.3);
  box-shadow: none;
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
}

.glass-effect:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(31, 38, 135, 0.5);
  border: 1px solid transparent;
}

/* Metric Cards - Updated to match login.css */
.metric-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.5rem;
  border-radius: 20px;
  border: 1px solid rgba(104, 139, 255, 0.3) !important;
  box-shadow: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
}

.metric-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 36px 0 rgba(31, 38, 135, 0.45);
  border: 1px solid transparent;
}

.metric-card h4 {
  font-size: 1.1rem;
  color: var(--text-color);
  margin-bottom: 1rem;
  font-weight: 500;
}

.metric-value {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, var(--primary-color), #a5b4ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Chart Container - Updated to match login.css */
.chart-container {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.chart-container h3 {
  font-size: 1.4rem;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 500;
}

.chart-wrapper {
  max-height: 370px;
  width: 100%;
  position: relative;
}

.efficiency-container {
  margin: 0px 5rem;
} 

/* Tables - Updated to match login.css */
.parameters-table,
.mass-balance-container {
  height: 100%;
  /* background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px); */
  border-radius: 20px;
  border: 1px solid rgba(104, 139, 255, 0.3) !important;
  box-shadow: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
}

.parameters-table:hover,
.mass-balance-table:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 36px 0 rgba(31, 38, 135, 0.45);
  border: 1px solid transparent;
}

.parameters-table h3,
.mass-balance-table h3,
.sankey-container h3 {
  font-size: 1.4rem;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 500;
}

.param-label {
  font-weight: 500;
  color: var(--text-color);
}

.total-row {
  background-color: rgba(104, 139, 255, 0.05);
}

/* Sankey Diagram - Updated to match login.css */
.sankey-container {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  margin-bottom: 2rem;
}

.sankey-wrapper {
  height: 400px;
  width: 100%;
}

/* Loading Container */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  color: var(--text-color);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .simulation-container {
    padding: 1rem;
  }

  .metric-card {
    margin-bottom: 1rem;
  }

  .metric-value {
    font-size: 1.5rem;
  }

  .simulation-header h2 {
    font-size: 1.6rem;
    margin: 1.5rem 0 1rem;
  }
  
  .simulation-header .lead {
    font-size: 1rem;
  }
  
  .run-simulation-btn {
    width: 100%;
    max-width: 350px;
    font-size: 18px;
    padding: 12px 20px;
  }
  
  .sim-form-card {
    padding: 1.25rem 1.25rem 0.75rem 1.25rem;
  }
  
  .sim-form-card h3 {
    font-size: 1.1rem;
  }
  
  .disclaimer-text {
    font-size: 0.75rem;
    margin-top: 12px;
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .run-simulation-btn {
    font-size: 16px;
    padding: 10px 15px;
  }
  
  .disclaimer-text {
    font-size: 0.7rem;
  }
}

/* Form Specific Styles */
.simulation-form-container {
  max-width: 1100px;
  margin: 0 auto;
}

/* Form Card - Enhanced default shadow */
.sim-form-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.75rem 1.75rem 1rem 1.75rem;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0 10px 30px rgba(31, 38, 135, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.sim-form-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(31, 38, 135, 0.5);
}

.sim-form-card h3 {
  font-size: 1.3rem;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  font-weight: 600;
  border-bottom: 2px solid rgba(104, 139, 255, 0.3);
  padding-bottom: 0.75rem;
  text-align: center;
}

.sim-form-group {
  margin-bottom: 1.25rem;
  height: calc(100% - 0.5rem);
}

.sim-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-color);
  font-size: 0.95rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Form Controls - Updated to match login.css */
.form-control {
  padding-left: 20px !important;
  background: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  border: 1px solid rgba(81, 78, 78, 0.18) !important;
  transition: all 0.3s ease !important;
}

.form-control:focus {
  background: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid rgba(74, 166, 237, 0.5) !important;
  box-shadow: 0 0 0 2px rgba(74, 166, 237, 0.1) !important;
}

.simulation-description {
  font-size: 0.9rem;
  color: var(--secondary-text);
  margin-top: 1rem;
  padding: 0.75rem;
  background: rgba(104, 139, 255, 0.05);
  border-radius: 8px;
  border-left: 3px solid rgba(104, 139, 255, 0.3);
}

.simulation-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

/* Run Simulation Button - Updated for better contrast */
.run-simulation-btn {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.8), rgba(151, 164, 225, 0.8));
  color: white;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  font-family: 'Bio Sans', sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 200px;
  height: 2.6rem;
  margin-top: 20px;
  letter-spacing: 0.5px;
  box-shadow: 0 6px 12px rgba(0, 86, 179, 0.3);
}

.run-simulation-btn:hover:not(:disabled) {
  background: linear-gradient(135deg, rgba(1, 78, 160, 0.8), rgba(151, 164, 225, 0.8));
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 86, 179, 0.4);
  color: #ffffff;
}

.run-simulation-btn:disabled {
  background: linear-gradient(180deg, #6c757d, #5a6268);
  cursor: not-allowed;
  transform: none;
  color: #ffffff;
  opacity: 0.8;
}

/* Disclaimer Text */
.disclaimer-text {
  font-size: 0.8rem;
  color: #6c757d;
  text-align: center;
  margin-top: 15px;
  max-width: 700px;
  line-height: 1.4;
  padding: 0 15px;
}

/* Digital Twin Page Styles */
.digital-twin-container {
  max-width: 1200px;
  margin: 0 auto;
}

.content-wrapper {
  margin: 2rem 0;
}

.error-alert {
  margin-bottom: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(220, 53, 69, 0.15);
}

/* Ensure the form container doesn't add extra padding when inside the digital twin page */
.digital-twin-page .simulation-form-container {
  padding-top: 0;
  padding-bottom: 0;
  background: transparent;
}

/* Results Page Styles */
.results-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.results-error-container {
  padding: 1.5rem;
}

.results-container {
  min-height: 100vh;
}

/* Spinner Styles */
.spinner-border {
  width: 3rem;
  height: 3rem;
}

/* Alert Styles */
.alert-danger {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(220, 53, 69, 0.15);
}

.alert-heading {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Results Email Modal Styles */
.results-email-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.results-email-modal {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95));
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 24px rgba(104, 139, 255, 0.12),
              0 1px 2px rgba(255, 255, 255, 0.3);
  animation: slideUp 0.3s ease-out;
}

.results-email-modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid rgba(130, 146, 250, 0.2);
}

.results-email-modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0f172a;
  margin: 0;
  text-align: center;
  background: linear-gradient(90deg, #007AFF, #a5b4ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.results-email-modal-body {
  padding: 18px 24px;
  max-height: 70vh;
  overflow-y: auto;
}

.results-email-form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.results-email-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #475569;
}

.results-email-input-wrapper {
  position: relative;
}

.results-email-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid rgba(130, 146, 250, 0.3);
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.05);
}

.results-email-input:focus {
  outline: none;
  border-color: #007AFF;
  box-shadow: 0 0 0 3px rgba(130, 146, 250, 0.15);
}

.results-email-input.is-invalid {
  border-color: var(--error-color);
}

.results-email-error {
  color: var(--error-color);
  font-size: 0.75rem;
  margin-top: 4px;
}

.results-email-modal-footer {
  padding: 0px 24px 20px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.results-email-button {
  padding: 8px 16px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.1);
}

.results-email-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(104, 139, 255, 0.15);
}

.results-email-button-primary {
  background: linear-gradient(180deg, #007AFF, #97a4e1a8);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.results-email-button-primary:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.results-email-button-secondary {
  background: rgba(255, 255, 255, 0.9);
  color: #2d3250;
  border: 1px solid rgba(98, 116, 229, 0.566);
}
.results-email-button-secondary:hover {
  background: linear-gradient(180deg, #007AFF, #97a4e1a8);
  color: #ffffff;
  border: 1px solid rgba(98, 116, 229, 0.566);
}

@media (max-width: 640px) {
  .results-email-modal {
    width: 95%;
  }

  .results-email-modal-header,
  .results-email-modal-body,
  .results-email-modal-footer {
    padding: 16px;
  }
}

/* Digital Twin Results Page Styles */
.card-title {
  text-align: center;
  margin-bottom: 1rem;
  color: #555;
}

.glass-effect {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Enhanced LLM Advisor Styles */
.llm-advisor {
  height: 100%;
  padding: 1.5rem;
  border: 1px solid rgba(104, 139, 255, 0.3);
  box-shadow: none;
  transition: all 0.3s ease;
  border-radius: 20px;
}

.llm-advisor:hover {
  box-shadow: 0 12px 36px 0 rgba(31, 38, 135, 0.45);
  transform: translateY(-5px);
  border: 1px solid transparent;
}

.advisor-title {
  font-size: 1.4rem;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
  background: linear-gradient(90deg, var(--primary-color), #a5b4ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid rgba(104, 139, 255, 0.2);
}

.advisor-messages {
  height: 300px;
  overflow-y: auto;
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.05);
  position: relative;
}

.message-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.message {
  padding: 12px 16px;
  border-radius: 18px;
  max-width: 85%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.user-message {
  align-self: flex-end;
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.1), rgba(151, 164, 225, 0.2));
  border: 1px solid rgba(0, 122, 255, 0.2);
  margin-left: 15%;
}

.user-message:hover {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.15), rgba(151, 164, 225, 0.25));
}

.bot-message {
  align-self: flex-start;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(240, 240, 240, 0.9));
  border: 1px solid rgba(200, 200, 200, 0.3);
  margin-right: 15%;
}

.bot-message:hover {
  background: linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(240, 240, 240, 1));
}

.loading-message {
  background: linear-gradient(135deg, rgba(240, 240, 240, 0.7), rgba(230, 230, 230, 0.7));
  border: 1px dashed rgba(200, 200, 200, 0.5);
}

.message-content {
  margin-bottom: 8px;
  line-height: 1.5;
  color: var(--text-color);
}

.message-timestamp {
  font-size: 0.7rem;
  color: rgba(45, 50, 80, 0.6);
  text-align: right;
}

.message-input-container {
  display: flex;
  gap: 10px;
  margin-bottom: 0.5rem;
}

.advisor-input {
  padding: 12px 18px !important;
  border-radius: 25px !important;
  background: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  border: 1px solid rgba(130, 146, 250, 0.3) !important;
  box-shadow: 0 2px 10px rgba(104, 139, 255, 0.05) !important;
  transition: all 0.3s ease !important;
}

.advisor-input:focus {
  background: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid rgba(0, 122, 255, 0.5) !important;
  box-shadow: 0 0 0 3px rgba(0, 122, 255, 0.1) !important;
}

.send-button {
  border-radius: 50% !important;
  width: 45px !important;
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.8), rgba(151, 164, 225, 0.8)) !important;
  border: none !important;
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.15) !important;
  transition: all 0.3s ease !important;
}

.send-button:hover:not(:disabled) {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.9), rgba(151, 164, 225, 0.9)) !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 6px 16px rgba(0, 122, 255, 0.25) !important;
}

.send-button:disabled {
  opacity: 0.7 !important;
  cursor: not-allowed !important;
}

.scroll-button {
  position: absolute !important;
  bottom: 15px !important;
  right: 15px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.8), rgba(151, 164, 225, 0.8)) !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.2) !important;
  border: none !important;
  opacity: 0.8 !important;
  transition: all 0.3s ease !important;
  z-index: 10 !important;
}

.scroll-button:hover {
  opacity: 1 !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 6px 16px rgba(0, 122, 255, 0.3) !important;
}

.advisor-footer {
  text-align: center;
  font-size: 0.8rem;
  color: rgba(45, 50, 80, 0.6);
  margin-top: 0.5rem;
  font-style: italic;
}

/* Responsive adjustments for LLM Advisor */
@media (max-width: 768px) {
  .advisor-messages {
    height: 250px;
  }
  
  .advisor-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .message {
    padding: 10px 14px;
    max-width: 90%;
  }
}

@media (max-width: 576px) {
  .llm-advisor {
    padding: 1rem;
  }
  
  .advisor-messages {
    height: 220px;
    padding: 0.75rem;
  }
  
  .message {
    padding: 8px 12px;
    max-width: 95%;
  }
  
  .advisor-input {
    padding: 10px 15px !important;
  }
  
  .send-button {
    width: 40px !important;
    height: 40px !important;
  }
}

/* Simulation Yield Display */
.progress-container {
  margin-top: 1rem;
  width: 100%;
}

/* Parameter Styles */
.param-label {
  font-weight: bold;
}

/* Math Equation Styles */
.math-equations {
  font-family: 'CMU Serif', Georgia, 'Times New Roman', serif;
}

.math-equations h5 {
  font-size: 1rem;
}

.equation-wrapper {
  padding: 1px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  margin: 10px 0;
  font-size: 0.9rem;
}

.equation {
  font-size: 1rem;
  text-align: center;
  color: #333;
}

.equation-solution {
  text-align: center;
  color: #1565C0;
  font-weight: 500;
  font-size: 0.8rem;
}

.equation-note {
  font-size: 0.85rem;
  text-align: center;
  color: #666;
  margin-top: 5px;
}

/* Custom Add Button */
.custom-add-btn {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.8), rgba(151, 164, 225, 0.8));
  color: white;
  border: none;
  border-radius: 10px;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.15);
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 2.3rem;
}

.custom-add-btn:hover {
  background: linear-gradient(135deg, rgba(151, 164, 225, 0.9), rgba(0, 122, 255, 0.9));
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 122, 255, 0.25);
  color: white;
}

.custom-add-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(0, 122, 255, 0.2);
}

.custom-add-btn i {
  font-size: 0.9rem;
}

/* Responsive adjustments for the side-by-side layout */
@media (max-width: 576px) {
  .sim-form-group label {
    font-size: 0.9rem;
  }
  
  .sim-form-card {
    padding: 1.5rem 1.25rem 0.75rem 1.25rem;
  }
}

.results-email-icon {
  -webkit-text-fill-color: initial;
  background: none;
  margin-right: 5px;
}

/* Extraction Results Table - Enhanced styling */
.extraction-results-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 1.5rem;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(31, 38, 135, 0.1);
}

/* Header styling */
.extraction-results-table .table-header-row {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.1), rgba(151, 164, 225, 0.2));
}

.extraction-results-table .table-header-row th {
  padding: 14px 16px;
  font-weight: 600;
  color: var(--text-color);
  border-bottom: 2px solid rgba(104, 139, 255, 0.2);
  text-transform: uppercase;
  font-size: 0.85rem;
  letter-spacing: 0.5px;
}

.extraction-results-table .table-header-row th:first-child {
  padding-left: 20px;
  border-top-left-radius: 12px;
}

.extraction-results-table .table-header-row th:last-child {
  padding-right: 20px;
  border-top-right-radius: 12px;
}

/* Row styling with proper spacing */
.extraction-results-table .table-data-row {
  transition: background-color 0.2s ease;
  height: 60px;
}

.extraction-results-table .table-data-row:not(:last-child) {
  border-bottom: 1px solid rgba(104, 139, 255, 0.1);
}

.extraction-results-table .table-data-row:hover {
  background-color: rgba(104, 139, 255, 0.05);
}

/* Cell styling with different padding for different columns */
.extraction-results-table .table-data-row td {
  vertical-align: middle;
}

.extraction-results-table .table-data-row td:first-child {
  padding: 12px 16px 12px 20px;
  font-weight: 500;
}

.extraction-results-table .table-data-row td:nth-child(2) {
  padding: 12px 10px;
  text-align: center;
  font-weight: 500;
  color: #1565C0;
}

.extraction-results-table .table-data-row td:last-child {
  padding: 12px 20px 12px 16px;
}

/* Total row styling */
.extraction-results-table .table-data-row.table-secondary {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.05), rgba(151, 164, 225, 0.1));
  font-weight: 600;
}

.extraction-results-table .table-data-row.table-secondary td {
  border-top: 2px solid rgba(104, 139, 255, 0.15);
  color: var(--text-color);
}

/* Extraction Results Summary Section */
.extraction-results-summary {
  padding: 1.5rem;
  border-radius: 20px;
  margin-top: 2rem;
  border: 1px solid rgba(104, 139, 255, 0.3);
  box-shadow: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
}

.extraction-results-summary:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 36px 0 rgba(31, 38, 135, 0.45);
  border: 1px solid transparent;
}

.extraction-results-summary h4 {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid rgba(104, 139, 255, 0.2);
}

/* Download Results Button */
.download-results-btn {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.8), rgba(151, 164, 225, 0.8));
  color: white;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  border: none;
  border-radius: 10px;
  padding: 8px 16px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.15);
  margin-top: 1rem;
}

.download-results-btn:hover {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.9), rgba(151, 164, 225, 0.9));
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 122, 255, 0.25);
  color: white;
}

/* Progress bar gradient backgrounds */
.progress-bar.bg-high {
  background: linear-gradient(90deg, #1eb980, #28a745) !important;
}

.progress-bar.bg-medium {
  background: linear-gradient(90deg, #ffb01f, #ffc107) !important;
}

.progress-bar.bg-low {
  background: linear-gradient(90deg, #ff5252, #dc3545) !important;
}

/* Legend item dots with gradients */
.legend-item-dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.legend-item-dot.high {
  background: linear-gradient(90deg, #1eb980, #28a745);
}

.legend-item-dot.medium {
  background: linear-gradient(90deg, #ffb01f, #ffc107);
}

.legend-item-dot.low {
  background: linear-gradient(90deg, #ff5252, #dc3545);
}

/* Action Grid for 2x2 button layout */
.action-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 500px;
}

.feedback-container h3 {
  text-align: center;
  font-size: 1.4rem;
}

.action-button-small {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.8), rgba(151, 164, 225, 0.8));
  color: white;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  border: none;
  border-radius: 10px;
  padding: 8px 12px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  width: 100%;
  margin: 0 auto;
}

.action-button-small:hover {
  background: linear-gradient(135deg, rgba(0, 122, 255, 0.9), rgba(151, 164, 225, 0.9));
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 122, 255, 0.25);
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .action-grid {
    margin-top: 20px;
  }
}

/* Responsive layout adjustments */
@media (max-width: 991.98px) {
  .simulation-container .mb-lg-0 {
    margin-bottom: 1.5rem !important;
  }
  
  .simulation-container .h-100 {
    height: auto !important;
  }
}

/* Ensure the math equations display properly on small screens */
@media (max-width: 576px) {
  .equation, .equation-solution {
    font-size: 0.9rem;
    overflow-x: auto;
  }
  
  .extraction-results-table {
    font-size: 0.9rem;
  }
  
  .extraction-results-table .table-header-row th {
    padding: 10px 12px;
    font-size: 0.8rem;
  }
  
  .extraction-results-table .table-data-row td {
    padding: 8px 12px;
  }
}

/* Updated navigation styles to match the theme */
.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 10px 0;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.nav-logo {
  display: flex;
  align-items: center;
}

.newton-logo {
  height: 40px;
  width: auto;
}

.nav-actions {
  display: flex;
  gap: 15px;
}

.nav-action-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #0048ff;
  font-size: 0.85rem;
  transition: color 0.2s;
}

.nav-action-link:hover {
  color: #2196f3;
  text-decoration: none;
}

.nav-action-icon {
  font-size: 1.2rem;
  margin-bottom: 3px;
  color: #0045adba;
}

.nav-action-text {
  font-weight: 500;
}

.robot-icon {
  color: #0045add5;
}

/* Collapsible Sections */
.collapsible-header {
  padding: 15px;
  border-radius: 10px;
}

.collapsible-header h3 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  background: linear-gradient(90deg, var(--primary-color), #a5b4ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.collapsible-header h4 {
  margin-bottom: 0;
  font-weight: 600;
  color: var(--text-color);
}

.collapsible-icon {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
  transition: all 0.3s ease;
}

.collapsible-section {
  margin-bottom: 1rem;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(104, 139, 255, 0.3);
  box-shadow: none;
}

.collapsible-section:hover {
  box-shadow: 0 8px 25px rgba(31, 38, 135, 0.15);
  border: 1px solid transparent;
}

.collapsible-content {
  padding: 0 45px 15px 45px;
  animation: fadeIn 0.3s ease-out;
}

.chart-description {
  font-size: 0.9rem;
  color: var(--secondary-text);
  text-align: center;
  padding: 0 15px;
}

/* Disclaimer Footer */
.disclaimer-footer {
  border-top: 1px solid rgba(104, 139, 255, 0.1);
  padding-top: 20px;
}

.disclaimer-text {
  font-size: 0.85rem;
  color: #6c757d;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments for collapsible sections */
@media (max-width: 768px) {
  .collapsible-header h4 {
    font-size: 1.1rem;
  }
  
  .collapsible-icon {
    font-size: 1.3rem;
  }
  
  .collapsible-content {
    padding: 0 10px 10px 10px;
  }
  
  .disclaimer-text {
    font-size: 0.8rem;
    padding: 0 15px;
  }
}

/* Add this to your existing CSS */
.nav-action-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #0048ff;
  font-size: 0.85rem;
  transition: color 0.2s;
}

.nav-action-button:hover {
  color: #2196f3;
}

/* LLM Feedback Component Styles */
.feedback-container {
  border-radius: 12px;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

.feedback-container h3 {
  color: #333;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.custom-range {
  height: 8px;
  border-radius: 4px;
  background: rgba(200, 200, 200, 0.3);
}

.custom-range::-webkit-slider-thumb {
  background: #007AFF;
  width: 18px;
  height: 18px;
}

.score-display {
  font-size: 1.1rem;
  color: #007AFF;
  display: inline-block;
  padding: 4px 12px;
  background: rgba(0, 122, 255, 0.1);
  border-radius: 20px;
}

.feedback-textarea {
  background: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  transition: all 0.3s ease !important;
}

.feedback-textarea:focus {
  box-shadow: 0 0 0 3px rgba(0, 122, 255, 0.25) !important;
  border-color: #007AFF !important;
}

.feedback-submit-btn {
  background-color: #007AFF !important;
  border-color: #007AFF !important;
  border-radius: 8px !important;
  padding: 10px 24px !important;
  font-weight: 600 !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.feedback-submit-btn:hover:not(:disabled) {
  background-color: #0056b3 !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
}

.feedback-submit-btn:disabled {
  background-color: #ccc !important;
  border-color: #ccc !important;
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}

@media (max-width: 768px) {
  .feedback-container {
    padding: 15px !important;
  }
  
  .feedback-submit-btn {
    width: 100%;
    padding: 8px 16px !important;
  }
}