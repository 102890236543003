.customize-experience {
  padding: 1rem;
}

.step-header {
  margin-bottom: 2rem;
}

.step-header h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.estimated-time {
  color: #666;
  font-size: 0.9rem;
}

.settings-card {
  margin-bottom: 2rem;
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.settings-section-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
}

.automation-settings {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.time-selector {
  margin-top: 1rem;
}

.time-selector label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
}

.parameter-inputs {
  padding: 1rem 0;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.get-started-btn {
  min-width: 150px;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 3em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position .15s ease-in-out;
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.skip-button:hover {
  background-color: #d0d0d0 !important; /* Darker gray on hover */
  color: #333 !important; /* Darker text on hover */
}
