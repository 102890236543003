.add-team-members {
  padding: 1rem;
}

.step-header {
  margin-bottom: 2rem;
}

.step-header h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.estimated-time {
  color: #666;
  font-size: 0.9rem;
}

.team-members-list {
  margin: 2rem 0;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.member-card {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.member-info h4 {
  margin: 0;
  font-size: 1.1rem;
}

.member-info p {
  margin: 0.5rem 0;
  color: #666;
}

.role-badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

.role-badge.admin {
  background-color: #e3f2fd;
  color: #1976d2;
}

.role-badge.operator {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.actions {
  margin: 2rem 0;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.add-member-btn {
  width: 100%;
  max-width: 200px;
}
