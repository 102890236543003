.connect-hardware {
  padding: 1rem;
}

.step-header {
  margin-bottom: 2rem;
}

.step-header h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.estimated-time {
  color: #666;
  font-size: 0.9rem;
}

.setup-card {
  margin-bottom: 2rem;
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.setup-step {
  cursor: pointer;
  transition: background-color 0.2s ease;
  border: none;
  border-bottom: 1px solid #e9ecef;
  padding: 1rem;
}

.setup-step:hover {
  background-color: #f8f9fa;
}

.setup-step.completed {
  background-color: #f0f9ff;
}

.step-content {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.step-icon {
  padding-top: 0.25rem;
}

.check-icon {
  color: #2eb85c;
  font-size: 1.25rem;
}

.circle-icon {
  color: #8a93a2;
  font-size: 1.25rem;
}

.step-text h4 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.step-text p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
