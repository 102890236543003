.onboarding-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
}

.onboarding-header {
  text-align: center;
  margin-bottom: 3rem;
}

.onboarding-header h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1rem;
  font-family: "Expansiva", sans-serif;
}

.onboarding-header p {
  color: #666;
  font-size: 1.1rem;
}

.progress-section {
  margin: 2rem 0;
}

.onboarding-progress {
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: #e9ecef;
}

.step-indicator {
  text-align: center;
  color: #666;
  font-size: 0.9rem;
}

.onboarding-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.step-indicators {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 2rem;
}

.step-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.step-number {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.step-label {
  font-size: 0.875rem;
  color: #666;
}

.step-indicator.completed .step-number {
  background-color: #2eb85c;
  color: white;
}

.step-indicator.current .step-number {
  background-color: #321fdb;
  color: white;
}

.step-indicator:hover .step-number {
  transform: scale(1.1);
}

.step-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 0 1rem;
}

.step-indicator.completed .step-label {
  color: #2eb85c;
}

.step-indicator.current .step-label {
  color: #321fdb;
  font-weight: 600;
}
