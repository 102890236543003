.system-overview-container {
  text-align: center;
}

.system-overview-title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
  margin-bottom: 2rem;
  background: linear-gradient(90deg, var(--primary-color), #a5b4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.system-overview-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #666666;
  margin-bottom: 2rem;
}

.system-health-status {
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 1rem;
}

.health-icon-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.health-status-text {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
}

/* Switch label styles */
.automation-switch-label {
  color: #1488f5;
  font-weight: 600;
}

.automation-switch {
  transform: scale(1.5);
  margin-right: 10px;
}

.form-switch .form-check-input {
  margin-right: 10px;
  background-color: rgb(255, 255, 255);
  border-color: #0b21e1b7;
  color: #0b21e1b7;
}

/* Add this new style to make the switch bluish when checked */
.form-switch .form-check-input:checked {
  background-color: #1488f5;
  border-color: #1488f5;
}

/* Schedule info styles */
.schedule-info {
  gap: 10rem;
}

.schedule-info-value {
  margin-left: 5px;
  font-weight: 700;
  color: #000000;
}

/* Card column styles */
.card-column {
  margin-bottom: 1rem;
  padding: 0.5rem;
}

/* Start measuring button */
.measure-button {
  background-color: #D8D8D8 !important;
  color: #000000 !important;
  /* border-color: #b9b9b9 !important; */
  width: 30%;
  margin-bottom: 1rem;
}

.measure-button:hover {
  background-color: #5522ca !important;
  color: #fcfcfc !important;
  /* border-color: #b9b9b9 !important; */
}

.measure-button.active {
  /* background-color: #3AC90A; */
  background: linear-gradient(135deg, rgba(3, 200, 16, 0.967), rgba(138, 247, 140, 0.938));
  border: 1px solid #3AC90A;
}

/* Media Queries */
@media screen and (max-width: 714px) and (max-height: 1200px) {
  .system-control-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .automation-switch-label {
    font-size: 12px;
  }

  .schedule-info {
    font-size: 12px;
  }

  .schedule-info-value {
    line-height: 30px;
    margin-bottom: 10px;
  }

  .measure-button {
    font-size: 12px;
    margin-top: -1rem;
    width: 50%;
  }

  .card-column {
    max-width: 350px;
  }
}

@media screen and (min-width: 1201px) {
  .system-control-title {
    font-size: 25px;
    line-height: 43.57px;
    margin-bottom: 20px;
  }

  .automation-switch-label {
    font-size: 12px;
  }

  .schedule-info {
    font-size: 14px;
  }

  .schedule-info-value {
    line-height: 43.57px;
    margin-bottom: 20px;
  }

  .measure-button {
    font-size: 14px;
    margin-top: 20px;
  }

  .card-column {
    max-width: 350px;
  }
}

/* Card Styles */
.system-card {
  background: linear-gradient(135deg, rgba(130, 146, 250, 0.15), rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(8px);
  border-radius: 12px;
  padding: 1rem;
  height: 350px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.system-card:hover {
  transform: translateY(-2px);
}

.card-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.card-icon {
  color: #1488f5;
}

.card-body {
  padding: 0px;
}

.card-title {
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #333;
  text-align: center;
}

.card-timestamp {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #666;
  text-align: center;
  margin-top: 0.5rem;
}

.card-content-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-status-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.2rem;
}

.card-label {
  color: grey;
  font-size: 12px;
  margin-left: 1.5rem;
}

.card-value {
  font-family: "Expansiva, sans-serif";
  display: flex;
  flex-direction: row;
  font-size: 12px;
}

.card-date {
  font-family: "Expansiva, sans-serif";
  font-size: 11px;
}

.card-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.card-action-button {
  padding: 8px 15px;
  font-size: 13px;
}

.card-button-icon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

/* Device Select Styles */
.device-select-container {
  width: 100%;
  margin: 0.5rem 0;
  padding: 0px 10px;
}

.scc-device-select {
  height: 36px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  margin: 2rem 0px;
}

.btn-padding {
  padding: 0px 10px;
}

/* Button Updates */
.action-button {
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: -7px;
  color: #292828;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #b0b0b0, #e0e0e0);
  backdrop-filter: blur(6px);
  border: 1px solid rgba(130, 146, 250, 0.3);
}

.action-button:hover {
  background: linear-gradient(135deg, #2f45b4a8), #62a5ee);
  /* transform: scale(1.05); */
  box-shadow: 0 4px 12px rgba(130, 146, 250, 0.3);
}

.system-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* Responsive Styles */
@media screen and (max-width: 714px) {
  .system-overview-title {
    font-size: 20px;
  }

  .system-overview-subtitle {
    font-size: 14px;
  }

  .system-health-status {
    padding: 1rem;
    flex-direction: column;
    text-align: center;
  }

  .health-status-text {
    font-size: 16px;
  }

  .next-check-container {
    font-size: 12px;
  }

  .automation-section {
    padding: 1rem;
    margin: 0.5rem auto;
  }

  .automation-label {
    font-size: 14px;
  }

  .card-title {
    font-size: 16px;
  }

  .system-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin: 0 3rem;
  }

  .scc-device-select {
    height: 32px;
    font-size: 12px;
    margin: 1rem 0;
  }

  .action-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .system-card {
    max-width: 220px;
    height: 280px;
    margin-top: 1rem;
    padding: 0.75rem;
  }

  .card-icon-container {
    height: 60px;
  }

  .card-icon {
    font-size: 40px;
  }

  .card-label {
    font-size: 10px;
  }

  .card-value {
    font-size: 10px;
  }

  .card-date {
    font-size: 8px;
  }

  .card-button-container {
    margin-top: 3rem;
  }

  .card-action-button {
    padding: 5px 10px;
    font-size: 12px;
  }

  .card-button-icon {
    margin-right: 3px;
    width: 12px;
    height: 12px;
  }

  .measure-button-container {
    margin: 1rem 0;
  }
}

@media screen and (max-width: 990px) and (min-width: 714px) {
  .system-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin: 0 2rem;
  }

  .container {
    max-width: 1063px;
  }
}

@media screen and (max-width: 1200px) {
  .automation-switch-label {
    font-size: 14px;
  }

  .schedule-info {
    font-size: 14px;
    gap: 7rem;
  }

  .container {
    max-width: 1063px;
  }
}

@media screen and (max-width: 1200px) and (max-height: 800px) and (orientation: landscape) {
  .system-overview-title {
    font-size: 22px;
  }

  .system-overview-subtitle {
    font-size: 14px;
  }

  .system-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }

  .system-health-status {
    margin-bottom: 0.5rem;
  }

  .health-status-text {
    font-size: 16px;
  }

  .schedule-info {
    font-size: 14px;
    gap: 7rem;
  }

  .automation-section {
    max-width: 600px;
  }

  .system-card {
    height: 310px;
    margin-top: -0.5rem;
  }

  .scc-device-select {
    margin: 1rem 0;
  }

  .system-control-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .schedule-info {
    font-size: 18px;
    margin-top: -10px;
  }

  .card-button-container {
    margin-top: 3.5rem;
  }

  .action-button-container {
    margin-top: 1.5rem;
  }

  .card-icon-container {
    height: 70px;
  }

  .card-icon {
    font-size: 60px;
    margin: -20px 0;
  }

  .card-title {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }

  .card-content-row {
    margin-top: 5px;
  }

  .measure-button-container {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 790px) {
  .system-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
  }

  .schedule-info-value{
    display: block;
  }

  .system-card {
    width: 250px;
    height: 290px;
    margin-top: 1rem;
    padding: 0.75rem;
  }
}

/* Automation Toggle Section */
.automation-section {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 1.5rem;
  margin: 1rem auto;
  max-width: 800px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.automation-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.automation-label {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #1488f5;
  font-weight: 600;
}

.automation-status {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #666666;
  margin-top: 0.5rem;
}

/* Animated Flow Strip */
.flow-strip-container {
  position: fixed;
  /* top: 4rem;
  left: 0; */
  width: 100%;
  height: 4px;
  background: rgba(130, 146, 250, 0.1);
  overflow: hidden;
  z-index: 100;
}

.flow-strip {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(26, 238, 58, 0.839),
    #3fcd44,
    rgba(128, 244, 129, 0.872),
    transparent
  );
  z-index: -1;
}

/* When system is unhealthy, change color */
.flow-strip.unhealthy {
  background: linear-gradient(
    90deg,
    transparent,
    rgba(220, 53, 69, 0.5),
    #dc3545,
    rgba(220, 53, 69, 0.5),
    transparent
  );
}

/* Blip Animation */
@keyframes blipAnimation {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.health-blip {
  animation: blipAnimation 1s ease-in-out infinite;
}

.health-indicator {
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: color 0.3s ease;
  margin-left: -20px;
  margin-right: 10px;
  margin-top: -10px;
}

/* Schedule Automation Modal Styles */
.schedule-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-modal {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95));
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 24px rgba(104, 139, 255, 0.12),
              0 1px 2px rgba(255, 255, 255, 0.3);
  animation: scheduleSlideUp 0.3s ease-out;
}

.schedule-modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid rgba(130, 146, 250, 0.2);
}

.schedule-modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0f172a;
  margin: 0;
  background: linear-gradient(90deg, #007AFF, #a5b4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.schedule-modal-body {
  padding: 24px;
}

.schedule-form-group {
  margin-bottom: 1.5rem;
}

.schedule-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #475569;
  margin-bottom: 0.5rem;
  display: block;
  text-align: start;
}

.schedule-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid rgba(130, 146, 250, 0.3);
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.05);
}

.schedule-input:focus {
  outline: none;
  border-color: #007AFF;
  box-shadow: 0 0 0 3px rgba(130, 146, 250, 0.15);
}

.schedule-select {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid rgba(130, 146, 250, 0.3);
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.05);
}

.schedule-select:focus {
  outline: none;
  border-color: #007AFF;
  box-shadow: 0 0 0 3px rgba(130, 146, 250, 0.15);
}

.schedule-modal-footer {
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid rgba(130, 146, 250, 0.2);
}

.schedule-button {
  padding: 8px 16px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.1);
}

.schedule-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(104, 139, 255, 0.15);
}

.schedule-button-primary {
  background: linear-gradient(135deg, #007AFF, #a5b4ff);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.schedule-button-secondary {
  background: rgba(255, 255, 255, 0.9);
  color: #2d3250;
  border: 1px solid rgba(130, 146, 250, 0.2);
}

.error-message {
  color: #dc3545;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

@keyframes scheduleSlideUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 640px) {
  .schedule-modal {
    width: 95%;
  }

  .schedule-modal-header,
  .schedule-modal-body,
  .schedule-modal-footer {
    padding: 16px;
  }
}

/* Scan Results Modal Styles */
.scan-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scan-modal {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95));
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 24px rgba(104, 139, 255, 0.12),
              0 1px 2px rgba(255, 255, 255, 0.3);
  animation: scanSlideUp 0.3s ease-out;
}

.scan-modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid rgba(130, 146, 250, 0.2);
}

.scan-modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0f172a;
  margin: 0;
  text-align: center;
  background: linear-gradient(90deg, #007AFF, #a5b4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.scan-modal-body {
  padding: 24px;
  text-align: center;
}

.scan-question {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #000000;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.scan-modal-footer {
  padding: 20px 24px;
  display: flex;
  justify-content: center;
  gap: 12px;
  border-top: 1px solid rgba(130, 146, 250, 0.2);
}

.scan-button {
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.1);
  min-width: 150px;
}

.scan-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(104, 139, 255, 0.15);
}

.scan-button-primary {
  background: linear-gradient(135deg, #007AFF, #a5b4ff);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.scan-button-secondary {
  background: rgba(255, 255, 255, 0.9);
  color: #2d3250;
  border: 1px solid rgba(130, 146, 250, 0.2);
}

@keyframes scanSlideUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 992px) {
  .scan-question {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .scan-button {
    padding: 8px 16px;
    font-size: 0.875rem;
    min-width: 120px;
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
      max-width: 1063px !important;
  }
}