/* Root Variables */
:root {
    --glass-bg: rgba(255, 255, 255, 0.65);
    --glass-border: rgba(255, 255, 255, 0.25);
    --glass-shadow: rgba(104, 139, 255, 0.1);
    --primary-color: #007AFF;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --success-color: #28a745;
  }
  
  .page-title {
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
    padding-bottom: 2px;
    background: linear-gradient(90deg, #007AFF, #97a4e1a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Search Styles */
  .search-container {
    width: 300px;
    margin-right: 1rem;
  }
  
  .search-input {
    border-radius: 25px;
    padding: 0.3rem 1rem;
    height: 40px;
    border: 1px solid #dee2e6;
    transition: border-color 0.2s ease-in-out;
    font-size: 0.9rem;
  }
  
  .search-input:focus {
    border-color: #0d6efd;
    box-shadow: 0 0 0 0.2rem rgba(13,110,253,.25);
  }
  
  /* Table Container */
  .table-container {
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    box-shadow: 0 4px 24px rgba(104, 139, 255, 0.12),
                0 1px 2px rgba(255, 255, 255, 0.3);
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
  
  /* Table Styles */
  .table-custom {
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  
  .table-custom thead th {
    font-weight: 600;
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 2px solid #dee2e6;
    background-color: #f8f9fa;
  }
  
  .table-custom tbody tr {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  /* Button Styles */
  .btn-custom {
    width: 120px;
    height: 40px;
    border-radius: 20px;
  }
  
  .btn-export {
    background: rgba(255, 255, 255, 0.9);
    color: #2d3250;
    border: 1px solid grey;
    border-radius: 12px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(104, 139, 255, 0.1);
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .btn-export:hover {
    background: linear-gradient(180deg, #007AFF, #97a4e1a8);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(104, 139, 255, 0.15);
  }
  
  /* Updated Action Buttons */
  .inventory-action-buttons {
    display: flex;
    gap: 0.5rem;
  }
  
  .custom-btn {
    padding: 4px 8px;
    font-size: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .btn-record-usage {
    background: linear-gradient(135deg, #007AFF, #97a4e1a8);
    color: white;
    border: none;
    min-width: 80px;
    height: 32px;
  }
  
  .btn-record-usage:hover {
    background: linear-gradient(315deg, #007AFF, #97a4e1a8);
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(104, 139, 255, 0.2);
  }
  
  .btn-edit {
    background: white;
    color: #007AFF;
    border: 1px solid #007AFF;
    width: 32px;
    height: 32px;
  }
  
  .btn-edit:hover {
    background: linear-gradient(135deg, #007AFF, #97a4e1a8);
    color: white;
    border: 1px solid transparent;
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(104, 139, 255, 0.2);
  }
  
  .btn-delete {
    background: white;
    color: #dc3545;
    border: 1px solid #dc3545;
    width: 32px;
    height: 32px;
  }
  
  .btn-delete:hover {
    background: linear-gradient(135deg, #dc3545, #c82333);
    color: white;
    border: 1px solid transparent;
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(220, 53, 69, 0.2);
  }
  
  /* Modal Styles */
  .inventory-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(8px);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .inventory-modal {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95));
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    width: 90%;
    max-width: 560px;
    box-shadow: 0 4px 24px rgba(104, 139, 255, 0.12),
                0 1px 2px rgba(255, 255, 255, 0.3);
    animation: slideUp 0.3s ease-out;
  }
  
  .inventory-modal-header {
    padding: 20px 24px;
    border-bottom: 1px solid rgba(130, 146, 250, 0.2);
  }
  
  .inventory-modal-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #0f172a;
    margin: 0;
    text-align: center;
    background: linear-gradient(90deg, #007AFF, #97a4e1a8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .inventory-modal-body {
    padding: 18px 24px;
    max-height: 70vh;
    overflow-y: auto;
  }
  
  .inventory-form-group {
    margin-bottom: 1rem;
  }
  
  .inventory-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #475569;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
  }
  
  .inventory-input-wrapper {
    position: relative;
  }
  
  .inventory-input {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid rgba(130, 146, 250, 0.3);
    border-radius: 8px;
    font-size: 0.875rem;
    transition: all 0.2s;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 8px rgba(104, 139, 255, 0.05);
  }
  
  .inventory-input:focus {
    outline: none;
    border-color: #007AFF;
    box-shadow: 0 0 0 3px rgba(130, 146, 250, 0.15);
  }
  
  .inventory-modal-footer {
    padding: 20px 24px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .inventory-button {
    padding: 8px 16px;
    border-radius: 12px;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(104, 139, 255, 0.1);
  }
  
  .inventory-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(104, 139, 255, 0.15);
  }
  
  .inventory-button-primary {
    background: linear-gradient(180deg, #007AFF, #97a4e1a8);
    color: white !important;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .inventory-button-secondary {
    background: rgba(255, 255, 255, 0.9);
    color: #2d3250;
    border: 1px solid rgba(130, 146, 250, 0.2);
  }
  
  @media (max-width: 640px) {
    .inventory-modal {
      width: 95%;
    }
    
    .inventory-modal-header,
    .inventory-modal-body,
    .inventory-modal-footer {
      padding: 16px;
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Status Indicator Styles */
  .status-indicator-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 18px 0px 12px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .status-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 16px;
    z-index: 1;
  }
  
  .status-progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: var(--progress);
    border-radius: 8px;
    transition: width 0.3s ease;
  }
  
  /* Tooltip Styles */
  .inventory-tooltip {
    padding: 8px;
    min-width: 200px;
  }
  
  .tooltip-title {
    font-weight: 600;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .tooltip-details {
    font-size: 0.9em;
    line-height: 1.4;
  }
  
  /* Status Colors */
  .status-indicator-wrapper[data-status="success"] .status-progress-bar {
    background: linear-gradient(
      to right,
      rgba(40, 167, 69, 0.1) 0%,
      rgba(40, 167, 69, 0.2) 100%
    );
  }
  
  .status-indicator-wrapper[data-status="warning"] .status-progress-bar {
    background: linear-gradient(
      to right,
      rgba(255, 193, 7, 0.1) 0%,
      rgba(255, 193, 7, 0.2) 100%
    );
  }
  
  .status-indicator-wrapper[data-status="danger"] .status-progress-bar {
    background: linear-gradient(
      to right,
      rgba(220, 53, 69, 0.1) 0%,
      rgba(220, 53, 69, 0.2) 100%
    );
  }
  
  /* Form grid layout */
  .inventory-form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 16px;
  }
  
  .inventory-full-width {
    grid-column: 1 / -1;
    width: 100%;
  }
  
  /* Responsive adjustments */
  @media (max-width: 640px) {
    .inventory-form-grid {
      grid-template-columns: 1fr;
      gap: 12px;
    }
  }
  
  /* Delete Modal Styles */
  .delete-modal {
    max-width: 450px;
  }
  
  .delete-confirmation-message {
    text-align: center;
    padding: 1rem 0;
  }
  
  .delete-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .delete-warning {
    color: var(--danger-color);
    font-weight: 500;
    margin-top: 1rem;
  }
  
  .inventory-button-danger {
    background: linear-gradient(180deg, #dc3545, #c82333);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .inventory-button-danger:hover {
    background: linear-gradient(180deg, #c82333, #bd2130);
  }