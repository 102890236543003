.set-background-image {
  background-image: url('../brand/NewtonInsightsBranding/NIBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.reg-main-header {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 32px;
  color: grey;
  text-align: center;
  font-style: italic;
}

.reg-image-container {
  padding: 5px;
  width: 100%;
  height: 50%;
  overflow: hidden;
  justify-content: right;
  align-items: center;
}

.reg-image-container img {
  max-width: 100%;
  max-height: 100%;
}

.reg-company-name {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: black;
  text-align: center;
  margin-right: 100px;
  margin-top: 30px;
  font-style: italic;
}

.login-link {
  font-family: 'Bio Sans', sans-serif;
  font-size: 18px;
  color: #8898a7;
  margin-top: 10px;
  margin-left: 15px;
  font-style: italic;
  border: none;
  background-color: transparent;
  text-decoration: underline;
}

.hidden-on-small {
  display: none;
}

@media (min-width: 1024px) {
  .hidden-on-small {
    display: block;
  }
}

@media (max-width: 600px) {
  .reg-image-container img {
    margin-left: 30px;
  }
}

.form-container {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  padding: 1.5rem;
}

.form-header {
  font-size: 1.5rem;
  line-height: 1.5;
}

.form-subheader {
  font-size: 1.25rem;
  font-weight: 600;
}

.name-inputs {
  display: flex;
  gap: 0rem;
  margin: 0.5rem 2.8rem;
  margin-bottom: 0.5rem;
}

.submit-button {
  padding: 0.25rem 1rem;
  background: linear-gradient(180deg, #007AFF, #97a4e1a8) !important;
  color: #fff;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  width: 60%;
  height: 32px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  border: none;
}

.submit-button:hover {
  background: linear-gradient(180deg, #97a4e1a8, #007AFF) !important;
  transform: scale(1.02);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.25);
}

.input-group2 {
  padding: 0px !important;
}
