.landing-container {
  min-height: 100vh;
  background-color: #f3f0fa87;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.justified-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.justified-line {
  line-height: 1;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 4rem;
}

.header-group {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-container img {
  max-width: 200px;
  height: auto;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.contact-link {
  font-family: 'Inter', sans-serif;
  color: #3e3d3d;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
  width: 100%;
  margin-left: 2rem;
}

.contact-link:hover {
  color: #007AFF;
}

.login-button {
  background: linear-gradient(180deg, #007AFF, #97a4e1a8);
  color: white;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  font-family: 'Bio Sans', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover {
  background: linear-gradient(180deg, #97a4e1a8, #007AFF);
  transform: scale(1.02);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.25);
}

.hero-section {
  text-align: center;
  padding: 4rem 2rem;
  max-width: 100%;
  margin: 0 auto;
  background-image: url('https://cdn.prod.website-files.com/658be563adc727c27b27070b/6735092f739a845d024af593_Group%201000005603.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  position: relative;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
}

.hero-title {
  /* font-family: 'Inter', sans-serif; */
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: #333;
}

.hero-title span.gradient-text,
.hero-title span:first-child {
  /* background: linear-gradient(180deg, #007AFF, #97a4e1a8); */
  -webkit-background-clip: text;
  background-clip: text;
  color: #2a2a2a;
}

.hero-title span {
  display: inline;
}

.hero-subtitle {
  /* font-family: 'Bio Sans', sans-serif; */
  font-size: 1.4rem;
  color: #666;
  margin-bottom: 0.5rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4;
  letter-spacing: -0.01em;
}

.hero-subtitle-container {
  margin-bottom: 3rem;
}

.hero-subtitle-secondary {
  /* font-family: 'Bio Sans', sans-serif; */
  line-height: 1.4;
  letter-spacing: -0.01em;
  margin: 0 auto;
  max-width: 100%;
}

.simulator-container {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(12px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  padding: 12rem;
  margin: 2rem auto;
  max-width: 900px;
}

.early-access-form {
  max-width: 500px;
  margin: 3rem auto;
}

.form-input {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(81, 78, 78, 0.18);
  border-radius: 8px;
  font-family: 'Bio Sans', sans-serif;
}

.landing-submit-button {
  padding: 0.5rem 1rem;
  background: linear-gradient(180deg, #007AFF, #97a4e1a8);
  color: #fff;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  width: 60%;
  height: 56px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  border: none;
}

.landing-submit-button:hover {
  background: linear-gradient(180deg, #007AFF, #97a4e1a8) !important;
  transform: translateY(-2px);
}

.bottom-text {
  text-align: center;
  font-family: 'Bio Sans', sans-serif;
  color: #666;
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 2rem;
}

@media (max-width: 768px) {
  .nav-container {
    padding: 1rem 2rem;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.25rem;
  }
}

.features-section {
  position: relative;
  background: #0A2540;
  color: white;
  margin-top: -6rem;
  padding: 6rem 2rem;
  clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 100%);
}

.features-content {
  max-width: 1200px;
  margin: 0 8rem;
}

.features-section h2 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  margin-top: 18rem;
  color: white;
}

.features-section p {
  margin-bottom: 8rem;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
}

.flow-diagram-section {
  padding: 6rem 2rem;
  background: #f6f9fc;
}

.flow-diagram-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 4rem;
  color: #0A2540;
}

.flow-diagram {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.flow-tree {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  position: relative;
}

.flow-group {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
}

.main-node {
  padding: 0.5rem 2rem;
  text-align: center;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.flow-item {
  width: 47vw;
  height: 55vh;
  position: relative;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease;
  overflow: hidden;
}

/* .flow-item::before {
  content: '';
  position: absolute;
  top: 20rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.85);
  transition: all 0.3s ease;
} */

.flow-item:hover::before {
  background: rgba(255, 255, 255, 0.95);
}

.dot {
  position: absolute;
  left: -3rem;
  top: 2rem;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: 2;
}

.content {
  position: relative;
  z-index: 1;
  padding-left: 1rem;
}

.content h3 {
  margin: 0 0 1rem;
  font-size: 2rem;
  color: #0A2540;
  font-weight: 600;
}

.content p {
  margin: 0;
  color: #4A5568;
  font-size: 1.2rem;
  line-height: 1.6;
}

.flow-item-container {
  position: relative;
  padding-left: 3rem;
  margin-bottom: 6rem;
  width: 100%;
  max-width: 600px;
}

.connector {
  position: absolute;
  left: 12px;
  top: 0px;
  width: 2px;
  background: #007AFF;
  transform-origin: top;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .flow-item {
    min-height: 250px;
    padding: 1.5rem;
  }

  .flow-item-container {
    padding-left: 2rem;
    margin-bottom: 4rem;
  }

  .content h3 {
    font-size: 1.2rem;
  }

  .content p {
    font-size: 1rem;
  }

  .dot {
    left: -2rem;
    width: 20px;
    height: 20px;
  }
}

.flow-connector {
  position: relative;
}

/* .flow-connector.left::after,
.flow-connector.right::after {
  content: '';
  position: absolute;
  top: 50%;
  height: 2px;
  background: #007AFF;
  transform-origin: left;
} */

.flow-connector.left::after {
  right: -30px;
  width: 30px;
}

.flow-connector.right::after {
  left: -30px;
  width: 30px;
}

.flow-section {
  padding: 4rem 2rem;
  background: #e9e8f5;
}

.flow-section h2 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 4rem;
  margin-top: 3rem;
  color: #0A2540;
}

.flow-container {
  max-width: 800px;
  margin: 0 20rem;
  padding: 2rem;
}

.flow-item-container {
  position: relative;
  padding-left: 3rem;
  margin-bottom: 4rem;
}

.connector {
  position: absolute;
  left: 12px;
  top: 0px;
  width: 2px;
  background: #007AFF;
  transform-origin: top;
}

.flow-item {
  position: relative;
  background: #fff;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.dot {
  position: absolute;
  left: -3rem;
  top: 1.5rem;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.content {
  padding-left: 1rem;
  background: white;
  border-radius: 9px;
  padding: inherit;
  opacity: 0.8;
}

/* Make sure items are visible on mobile */
@media (max-width: 768px) {
  .flow-container {
    padding: 1rem;
  }

  .flow-item-container {
    padding-left: 2rem;
  }

  .dot {
    left: -2rem;
    width: 20px;
    height: 20px;
  }
}

.justified-line h2 {
  background: linear-gradient(180deg, #007AFF, #97a4e1a8);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.arrow-down {
  position: absolute;
  bottom: -40px;
  left: 2%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #007AFF;
  z-index: 2;
}
