/* rundata.css */
@import url("../fonts/expansiva/style.css");

.page-title {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
}

.run-btn-custom {
  padding: 0.5rem 1rem;
  border-radius: 20px;
}

.btn-export {
  background: rgba(255, 255, 255, 0.9);
  color: #2d3250;
  border: 1px solid grey;
  border-radius: 12px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.1);
}

.btn-export:hover {
  background: linear-gradient(180deg, #007AFF, #97a4e1a8);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(104, 139, 255, 0.15);
}

.btn-save {
  right: 0;
  top: 10px;
}

.table-custom {
  border-collapse: separate;
  border-spacing: 0 10px;
}

/* Add new table container styles */
.table-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1rem;
}

.thead-light {
  background-color: #f8f9fa;
}

.table-row {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.btn-link-custom {
  color: #007AFF;
  text-decoration: none;
  padding: 0.25rem 0.75rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.btn-link-custom:hover,
.btn-link-custom:active,
.btn-link-custom:focus {
  background: linear-gradient(180deg, #007AFF, #97a4e1a8);
  color: white;
  text-decoration: none;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.15);
}

/* Remove default button focus styles */
.btn-link-custom:focus {
  box-shadow: none;
}

.btn-prev {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-next {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-prev,
.pagination-next {
  background: rgba(255, 255, 255, 0.9);
  color: #2d3250;
  border: 1px solid rgba(130, 146, 250, 0.2);
  border-radius: 12px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.1);
}

.pagination-prev:hover:not(:disabled),
.pagination-next:hover:not(:disabled) {
  background: linear-gradient(180deg, #007AFF, #97a4e1a8);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(104, 139, 255, 0.15);
}

.pagination-prev:disabled,
.pagination-next:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.page-number {
  margin: 4px 15px;
  font-size: 1.2rem;
  color: #758296;
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.5rem;
    text-align: center;
  }

  .btn-custom {
    width: 100px;
    height: 35px;
    border-radius: 15px;
  }

  .btn-export {
    left: 0px;
    top: 2.5rem;
  }

  .btn-save {
    right: 0px;
    top: 2.5rem;
  }

  .pagination-prev,
  .pagination-next {
    width: 100px;
    border-radius: 15px;
  }

  .page-number {
    font-size: 1rem;
  }

  /* Update table container styles for mobile */
  .table-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 1rem;
  }

  /* Ensure table takes full width of container */
  .table-custom {
    min-width: 800px; /* Adjust this value based on your table's content */
    width: 100%;
  }
}

/* Modal styles */
.modal-content {
  border-radius: 12px;
  border: none;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

.modal-header {
  border-bottom: 1px solid #eee;
  padding: 1.5rem;
}

.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  border-top: 1px solid #eee;
  padding: 1.5rem;
}

.form-control {
  border-radius: 8px;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  transition: border-color 0.2s ease-in-out;
}

.form-control:focus {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.2rem rgba(13,110,253,.25);
}

.form-label {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #495057;
}

/* Disabled input styles */
.form-control:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
}

/* Parameter Modal Styles */
.parameter-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parameter-modal {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95));
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: 90%;
  max-width: 560px;
  box-shadow: 0 4px 24px rgba(104, 139, 255, 0.12),
              0 1px 2px rgba(255, 255, 255, 0.3);
  animation: slideUp 0.3s ease-out;
}

.parameter-modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid rgba(130, 146, 250, 0.2);
}

.parameter-modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0f172a;
  margin: 0;
  text-align: center;
  background: linear-gradient(90deg, #007AFF, #a5b4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.parameter-close-button {
  background: none;
  border: none;
  color: #64748b;
  font-size: 24px;
  cursor: pointer;
  padding: 4px;
  border-radius: 6px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parameter-close-button:hover {
  background-color: #f1f5f9;
  color: #334155;
}

.parameter-modal-body {
  padding: 24px;
  max-height: 70vh;
  overflow-y: auto;
}

.parameter-form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
}

.parameter-form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.parameter-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #475569;
  text-transform: capitalize;
}

.parameter-input-wrapper {
  position: relative;
}

.parameter-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid rgba(130, 146, 250, 0.3);
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.05);
}

.parameter-input:focus {
  outline: none;
  border-color: #007AFF;
  border-color: #007AFF;
  box-shadow: 0 0 0 3px rgba(130, 146, 250, 0.15);
}

.parameter-modal-footer {
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid rgba(130, 146, 250, 0.2);
}

.parameter-button {
  padding: 8px 16px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.1);
}

.parameter-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(104, 139, 255, 0.15);
}

.parameter-button-primary {
  background: linear-gradient(180deg, #007AFF, #97a4e1a8);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.parameter-button-secondary {
  background: rgba(255, 255, 255, 0.9);
  color: #2d3250;
  border: 1px solid rgba(130, 146, 250, 0.2);
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 640px) {
  .parameter-modal {
    width: 95%;
  }

  .parameter-form-grid {
    grid-template-columns: 1fr;
  }

  .parameter-modal-header,
  .parameter-modal-body,
  .parameter-modal-footer {
    padding: 16px;
  }
}

/* Note Modal Styles */
.note-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-modal {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95));
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 24px rgba(104, 139, 255, 0.12),
              0 1px 2px rgba(255, 255, 255, 0.3);
  animation: slideUp 0.3s ease-out;
}

.note-modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid rgba(130, 146, 250, 0.2);
}

.note-modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0f172a;
  margin: 0;
  background: linear-gradient(90deg, #007AFF, #97a4e1a8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.note-close-button {
  background: none;
  border: none;
  color: #64748b;
  font-size: 24px;
  cursor: pointer;
  padding: 4px;
  border-radius: 6px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-close-button:hover {
  background-color: #f1f5f9;
  color: #334155;
}

.note-modal-body {
  padding: 24px;
  max-height: 70vh;
  overflow-y: auto;
}

.note-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.note-item {
  padding: 16px;
  background: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.note-timestamp {
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 8px;
  font-weight: 500;
}

.note-content {
  color: #334155;
  line-height: 1.5;
}

.note-empty {
  text-align: center;
  color: #64748b;
  padding: 32px;
  font-size: 0.875rem;
}

@media (max-width: 640px) {
  .note-modal {
    width: 95%;
  }

  .note-modal-header,
  .note-modal-body {
    padding: 16px;
  }

  .note-item {
    padding: 12px;
  }
}

/* Update these styles */
.search-container {
  width: 300px; /* Reduced from 300px */
  margin-right: 1rem;
}

.search-input {
  border-radius: 25px; /* More rounded corners */
  padding: 0.3rem 1rem; /* Reduced vertical padding */
  height: 40px; /* Specific height */
  border: 1px solid #dee2e6;
  transition: border-color 0.2s ease-in-out;
  font-size: 0.9rem; /* Slightly smaller font */
}

.search-input:focus {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.2rem rgba(13,110,253,.25);
}

/* Update table header styles */
.table-custom thead th {
  font-weight: 600;
  padding: 0.75rem;
  vertical-align: middle;
  border-bottom: 2px solid #dee2e6;
  background-color: #f8f9fa;
}

/* Add these styles */
.post-mass-input {
  width: 80px !important;
  height: 30px !important;
  display: inline-block;
}

.btn-link.text-danger {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn-link-edit {
  color: #0841ffb5;
}

.btn-link-edit:hover {
  color: #3360f3b5;
}

.btn-link-edit:focus {
  border: none;
  background: linear-gradient(180deg, #007AFF, #97a4e1a8);
  color: white;
  text-decoration: none;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(104, 139, 255, 0.15);
}

.btn-link.text-danger:hover {
  color: #dc3545;
}

.d-flex {
  gap: 0.25rem;
}

/* Graph Modal Styles */
.graph-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graph-modal {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95));
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 4px 24px rgba(104, 139, 255, 0.12),
              0 1px 2px rgba(255, 255, 255, 0.3);
  animation: slideUp 0.3s ease-out;
}

.graph-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid rgba(130, 146, 250, 0.2);
}

.graph-modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0f172a;
  margin: 0;
  background: linear-gradient(90deg, #007AFF 75%, #97a4e1a8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.graph-close-button {
  background: none;
  border: none;
  color: #64748b;
  font-size: 24px;
  cursor: pointer;
  padding: 4px;
  border-radius: 6px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graph-close-button:hover {
  background-color: #f1f5f9;
  color: #334155;
}

.graph-modal-body {
  padding: 24px;
  margin: 0 2rem;
  max-height: 80vh;
  overflow-y: auto;
}

@media (max-width: 640px) {
  .graph-modal {
    width: 95%;
  }

  .graph-modal-header,
  .graph-modal-body {
    padding: 16px;
  }
}


/* Export Dialog */
.export-checkbox {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.parameter-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #007bff;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
}

.checkbox-label {
  font-size: 16px;
}

.export-options-overlay {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}
.export-options-overlay button {
  display: block;
  margin: 5px 0;
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.export-options-overlay button:hover {
  background-color: #0056b3;
}

